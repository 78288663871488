<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }" @click.self="closeModal()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Contact Center Tabs</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="loader_area2" v-if="statusTabsLoader"><quote-loader/></div>
                <Form @submit="handleUpdate" v-slot="{ errors }" ref="status-tab-form" v-else>
                    <div class="tab_content switch_wpr">
                        <ul class="contact_center">
                            <li v-for="(group, type) in tabsList" :key="type">
                                <div class="toggle_area" @click="tabGroups[type] = !tabGroups[type]">
                                    <h5 class="capitalize">{{ type }}</h5>
                                    <button type="button" class="act_btn">{{ tabGroups[type] ? 'Hide' : 'Show'}}<i class="fas fa-angle-down"></i></button>
                                </div>
                                <div v-show="tabGroups[type]">
                                    <draggable v-model="tabsList[type]" item-key="id" tag="ul"  @change="handleTabsListSort(tabsList[type])" >
                                        <template #item="{ element, index }">
                                            <div class="leads_list" v-if="!element.is_hidden">
                                                <div class="leads_wpr">
                                                    <span><i class="fas fa-align-justify"></i></span>
                                                    <h4>{{element.title}}</h4>
                                                    <Field
                                                      type="text"
                                                      placeholder="New Leads"
                                                      :name="element.id ? type+'_'+element.id : type+'_'+index"
                                                      v-model="element.title"
                                                      :readonly="element.id && ((element.is_employer_deleted == 1 && user.is_organization_owner == false) || (element.is_employer_deleted != 1 && user.id != element.employer_id))"
                                                      :class="{ 'has-error': errors[type+'_'+element.id] }"
                                                      rules="required"
                                                      label="tab"
                                                    />
                                                    <button type="button" class="delete" v-if="(element.is_employer_deleted == 1 && user.is_organization_owner) || (user.id == element.employer_id) || !element.id" @click="removeTab(element, type, index)">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                    <button v-else type="button" disabled class="delete transparent-color">
                                                        <i class="fas fa-trash-alt"></i>
                                                    </button>
                                                </div>
                                                <ErrorMessage :name="type+'_'+index" class="text-danger tabs-error" />
                                            </div>
                                        </template>
                                        <template #footer>
                                            <div class="action_wpr">
                                                <button type="button" class="add_btn m-0" @click="addNewTab(type)">
                                                    <i class="fas fa-plus"></i>Add
                                                </button>
                                            </div>
                                        </template>
                                    </draggable>
                                </div>
                            </li>
                        </ul>
                    </div>
                </Form>
            </div>
            <div class="modal_footer">
                <button type="button" :disabled="loader" class="btn cancel_btn" @click="closeModel">Cancel</button>
                <button :disabled="loader" class="btn save_btn" @click="handleSubmit"><i class="fa fa-spinner fa-spin" v-if="loader"></i> {{ loader ? 'Updating' : 'Update' }}</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    import Helper from '@/utils/Helper'
    import Swal from 'sweetalert2'
    import Draggable from 'vuedraggable'

    export default {
        name: 'Contact Center Tabs',

        data () {
            return  {
                tabsList: {
                    leads: [],
                    trials: [],
                    clients:[],
                    staff:[],
                    lost:[]
                },
                tabGroups: [],
                loader: false,
            };
        },

        props: {
            modelValue: Boolean,
            module: {
                type: String,
                default: 'contact-center'
            }
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
            Draggable,
        },

        watch: {
            statuses (statuses) {
                const vm = this;

                const data = JSON.parse(JSON.stringify(statuses));

                vm.prepareTabsList(data);
            },
            modelValue(val) {
                if (val == true) {
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            statuses: state => state.contactModule.statuses,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        mounted () {
            const vm = this;

            if (Object.keys(vm.statuses).length) {
                vm.prepareTabsList(vm.statuses);
            }
        },

        methods: {
            ...mapActions({
                getStatuses: 'contactModule/getStatuses',
                deleteTab: 'contactModule/deleteStatusTab',
                updateTabs: 'contactModule/updateStatusTabs',
                tabsListSort: 'contactModule/tabsListSort',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            prepareTabsList (data) {
                const vm = this;

                for (let index in data) {
                    vm.tabsList[index] = data[index];
                }
            },

            addNewTab (type) {
                const vm = this;

                vm.tabsList[type].push({
                    type : type,
                    title: 'New',
                    value: '',
                    is_contacts: false,
                    deletable: true,
                });
            },

            removeTab (item, type, index) {
                const vm = this;

                if (!item.is_contacts) {
                    const options = Helper.swalConfirmOptions('Are you sure?', 'You will not be able to recover this tab.');
                          options.preConfirm = function () {
                              if (!item.id) {
                                  vm.tabsList[type].splice(index, 1);

                                  return true;
                              }

                              return vm.deleteTab({ id: item.id, title: item.title, module: vm.module }).then((status) => {
                                  if (status) {
                                      vm.tabsList[type].splice(index, 1);
                                  }
                              });
                          };

                    Swal.fire(options);
                } else {
                    const options = Helper.swalWarningOptions('Oops!', 'A tab must be empty to be deleted. Please switch existing contacts to another tab then delete your empty tab.');
                    Swal.fire(options);
                }
            },

            handleUpdate ({ setFieldError }) {
                const vm = this;
                const params = { tabs: vm.tabsList, module: vm.module, setFieldError };

                vm.loader = true;

                vm.updateTabs(params).then((status) => {
                    if (status) {
                        vm.closeModal();
                    }

                    vm.loader = false;
                });
            },

            handleSubmit () {
                const vm = this;
                const form = vm.$refs['status-tab-form'];

                form.validate().then((result) => {
                    if (result.valid) {
                        vm.handleUpdate(form);
                    }
                });
            },

            handleTabsListSort (data) {
                const vm = this;
                const ids = [];

                data.forEach((step) => {
                    ids.push(step.id);
                });

                vm.tabsListSort({ids});
            },
        },
    };
</script>

<style scoped>
.switch_wpr{
    padding: 15px 20px;
    background: #fff;
    border-radius: 5px;
    margin-bottom: 20px;
}
</style>
